import React from "react";

// Admin Imports
// import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
import DataTables from "views/admin/tables/system-prompts";
// import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  // MdHome,
  // MdOutlineShoppingCart,
  // MdPerson,
  MdLock,
  // MdRecommend,
} from "react-icons/md";
import {
  TbCurrentLocation,
  TbFileSettings,
  TbCode,
  TbActivity,
  TbArrowAutofitContent,
  TbTerminal2,
} from "react-icons/tb";
// import ReccommendedTripTable from "views/recommended";

import { ROUTES } from "constants";
import DestinationTables from "views/admin/tables/destinations";
import ActivitiesTables from "views/admin/tables/activities";
import WebContentsTables from "views/admin/tables/web-contents";
import WebPromptsTables from "views/admin/tables/web-prompts";
import CountriesTables from "views/admin/tables/countries";
// import { TbFileSettings } from "react-icons/tb";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },

  {
    name: "Prompt",
    layout: ROUTES.admin,
    // icon: <MdBarChart  />,
    icon: <TbCode className="h-6 w-6" />,
    path: "admin-prompt",
    component: <DataTables />,
  },
  {
    name: "Config",
    layout: ROUTES.admin,
    path: "config",
    // icon: <MdBarChart  />,
    icon: <TbFileSettings className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Destinations",
    layout: ROUTES.admin,
    path: "destinations",
    // icon: <MdBarChart  />,
    icon: <TbCurrentLocation className="h-6 w-6" />,
    component: <DestinationTables />,
    secondary: true,
  },
  {
    name: "Activities",
    layout: ROUTES.admin,
    path: "activities",
    // icon: <MdBarChart  />,
    icon: <TbActivity className="h-6 w-6" />,
    component: <ActivitiesTables />,
    secondary: true,
  },
  {
    name: "Website-contents",
    layout: ROUTES.admin,
    path: "web-contents",
    // icon: <MdBarChart  />,
    icon: <TbArrowAutofitContent className="h-6 w-6" />,
    component: <WebContentsTables />,
    secondary: true,
  },
  {
    name: "Website-prompts",
    layout: ROUTES.admin,
    path: "web-prompts",
    // icon: <MdBarChart  />,
    icon: <TbTerminal2 className="h-6 w-6" />,
    component: <WebPromptsTables />,
    secondary: true,
  },
  {
    name: "Countries",
    layout: ROUTES.admin,
    path: "countries",
    // icon: <MdBarChart  />,
    icon: <TbTerminal2 className="h-6 w-6" />,
    component: <CountriesTables />,
    secondary: true,
  },
  // {
  //   name: "Recommended Trips",
  //   layout: ROUTES.admin,
  //   path: "recommended_trips",
  //   icon: <MdRecommend className="h-6 w-6" />,
  //   component: <ReccommendedTripTable />,
  //   secondary: true,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    name: "Sign in",
    layout: ROUTES.auth,
    path: "default",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
