import { createContext, useContext, useState, useMemo } from "react";
const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const [subCurrentRoute, setSubCurrentRoute] = useState("");
  const initialFormState = useMemo(
    () => ({
      key: "",
      value: "",
      status: "",
      comment: "",
      configCategory: "llmCategory",
    }),
    []
  );
  const initialIterinaryFormData = useMemo(
    () => ({
      tripName: "",
      numberOfDaysSelected: 0,
      featured: "",
      topDestination: 0,
    }),
    []
  );
  const initialConfigState = useMemo(
    () => ({
      key: "",
      value: "",
      status: "",
      comment: "",
    }),
    []
  );
  const [formData, setFormData] = useState(initialFormState);
  const [iterinaryFormData, setIterinaryFormData] = useState(
    initialIterinaryFormData
  );

  // destination form State
  const initialDestinationFormState = useMemo(
    () => ({
      Name: "",
      Type: "",
      Title: "",
      Subtitle: "",
      Region: "",
    }),
    []
  );
  const [isOpenDestinationEditForm, setIsOpenDestinationEditForm] =
    useState(false);
  const [fetchDestinations, setFetchDestinations] = useState(false);
  const [destinationFormData, setDestinationFormData] = useState(
    initialDestinationFormState
  );

  // Activities data
  const initialActivitiesFormState = useMemo(
    () => ({
      Activity: "",
      Group: "",
      Subtitle: "",
    }),
    []
  );
  const [isOpenActivitiesEditForm, setIsOpenActivitiesEditForm] =
    useState(false);
  const [fetchActivities, setFetchActivities] = useState(false);
  const [activitiesFormData, setActivitiesFormData] = useState(
    initialActivitiesFormState
  );

  // Website data(web Contents) data
  const initialWebContentsFormState = useMemo(
    () => ({
      section: "",
      key: "",
      value: "",
    }),
    []
  );
  const [isOpenWebsiteContentsEditForm, setIsOpenWebsiteContentsEditForm] =
    useState(false);
  const [fetchWebContents, setFetchWebContents] = useState(false);
  const [webContentsFormData, setWebContentsFormData] = useState(
    initialWebContentsFormState
  );

  // Website Prompts data
  const initialWebPromptsFormState = useMemo(
    () => ({
      title: "",
      value: "",
      imageUrl: "",
      sortOrder: "",
    }),
    []
  );
  const [isOpenWebsitePromptsEditForm, setIsOpenWebsitePromptsEditForm] =
    useState(false);
  const [fetchWebPrompts, setFetchWebPrompts] = useState(false);
  const [webPromptsFormData, setWebPromptsFormData] = useState(
    initialWebPromptsFormState
  );

  // countries data
  const initialCountriesFormState = useMemo(
    () => ({
      name: "",
      code: "",
      iso: "",
    }),
    []
  );
  const [isOpenCountriesEditForm, setIsOpenCountriesEditForm] = useState(false);
  const [fetchCountries, setFetchCountries] = useState(false);
  const [countriesFormData, setCountriesFormData] = useState(
    initialCountriesFormState
  );

  // system prompts form state
  const [fetchSystemPrompts, setFetchSystemPrompts] = useState(false);

  // system config form state
  const [fetchConfig, setFetchConfig] = useState(false);

  // const [configData, setConfigData] = useState(initialConfigState);
  // const getConfigkeydata = async () => {
  //   try {
  //     // const response = await axios.get(`${ENDPOINT}/app/config`);
  //     const response = await axios.get(`${ENDPOINT}/app/v1/config`);
  //     if (response.data && response.data.data) {
  //       setTableData(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const [tableData, setTableData] = useState([]);
  const [iterinaryList, setIterinaryList] = useState([]);

  // const getSystemPromptData = async () => {
  //   try {
  //     // const response = await axios.get(`${ENDPOINT}/app/systemprompt`);
  //     const response = await axios.get(`${ENDPOINT}/app/v1/systemprompt`);
  //     if (response.data && response.data.data) {
  //       setTableData(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const value = {
    // destination form state values
    destinationFormData,
    setDestinationFormData,
    initialDestinationFormState,
    fetchDestinations,
    setFetchDestinations,
    isOpenDestinationEditForm,
    setIsOpenDestinationEditForm,

    // Activities form State values
    initialActivitiesFormState,
    isOpenActivitiesEditForm,
    setIsOpenActivitiesEditForm,
    fetchActivities,
    setFetchActivities,
    activitiesFormData,
    setActivitiesFormData,

    // Website contents(website data) form state values
    initialWebContentsFormState,
    isOpenWebsiteContentsEditForm,
    setIsOpenWebsiteContentsEditForm,
    fetchWebContents,
    setFetchWebContents,
    webContentsFormData,
    setWebContentsFormData,

    // Website prompts form State values
    initialWebPromptsFormState,
    isOpenWebsitePromptsEditForm,
    setIsOpenWebsitePromptsEditForm,
    fetchWebPrompts,
    setFetchWebPrompts,
    webPromptsFormData,
    setWebPromptsFormData,

    // Countries form state values
    initialCountriesFormState,
    isOpenCountriesEditForm,
    setIsOpenCountriesEditForm,
    fetchCountries,
    setFetchCountries,
    countriesFormData,
    setCountriesFormData,

    // System prompt form state values
    fetchSystemPrompts,
    setFetchSystemPrompts,

    // Config form state values
    fetchConfig,
    setFetchConfig,

    userDetails,
    setUserDetails,
    formData,
    setFormData,
    initialFormState,
    tableData,
    // getSystemPromptData,
    // getConfigkeydata,
    initialConfigState,
    setIterinaryList,
    iterinaryList,
    iterinaryFormData,
    setIterinaryFormData,
    initialIterinaryFormData,
    setCurrentRoute,
    currentRoute,
    subCurrentRoute,
    setSubCurrentRoute,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
