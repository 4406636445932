import axios from "axios";
import { ENDPOINT } from "constants/environment";

export const getCountriesAPI = async (isLoading) => {
  try {
    isLoading(true);
    const response = await axios.get(`${ENDPOINT}/app/v1/county`);
    if (response.data && response.data.statusCode === 200) {
      if (isLoading) isLoading(false);
      console.log(response, "response");
      return response?.data?.data;
    }
    if (isLoading) isLoading(false);
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error fetching data:", error);
    return null;
  }
};

export const createOrUpdateCountriesAPI = async (args, isLoading) => {
  const API_URL = `${ENDPOINT}/app/v1/county`;
  try {
    if (isLoading) isLoading(true);
    const response = args?.id
      ? await axios.put(API_URL, args)
      : await axios.post(API_URL, args);
    if (response.data && response.data.statusCode === 200) {
      if (isLoading) isLoading(false);
      return response?.data?.data;
    }
    if (isLoading) isLoading(false);
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error creating/updating country:", error);
    return null;
  }
};

export const deleteCountryAPI = async (args, isLoading) => {
  const API_URL = `${ENDPOINT}/app/v1/county?id=${args.id}`;
  try {
    if (isLoading) isLoading(true);
    const response = await axios.delete(API_URL);

    if (response.data) {
      if (isLoading) isLoading(false);
      return response?.data;
    }
    if (isLoading) isLoading(false);
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error deleting country:", error);
    return null;
  }
};
