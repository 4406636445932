import { useState, useEffect } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useGlobalContext } from "context/globalContext";
import toast from "react-hot-toast";
import { ENDPOINT } from "constants/environment";
export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setUserDetails } = useGlobalContext();

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // console.log(token, "tok");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        console.log(decodedToken, "decodedToken");
        if (!decodedToken?.id) {
          navigate("/");
          localStorage.removeItem("jwtToken");
        } else {
          navigate("/admin/admin-prompt");
        }
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
    // else {
    //   navigate("/");
    // }
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError("");

    if (email === "" || password === "") {
      setError("Please enter the credentials.");
      return;
    }

    try {
      // const response = await axios.post(`${ENDPOINT}/login`, {
      const response = await axios.post(`${ENDPOINT}/app/v1/auth/login`, {
        email,
        password,
        loginType: "Email",
        name: "",
      });

      if (response.status === 200) {
        console.log(response);
        if (response.data.statusCode === 400) {
          setError(response.data.message);
        } else {
          const { jwtToken } = response.data.data;
          localStorage.setItem("jwtToken", jwtToken);

          if (jwtToken) {
            try {
              const decodedToken = jwtDecode(jwtToken);
              setUserDetails(decodedToken);
              console.log(decodedToken, "decodedToken");
              if (
                !decodedToken?.id ||
                (decodedToken?.role !== 1 && decodedToken?.role !== 3)
              ) {
                navigate("/");
                localStorage.removeItem("jwtToken");
                setUserDetails({});
                toast.error("Invalid login credentials");
                return;
              }
            } catch (error) {
              console.error("Invalid token", error);
            }
          } else {
            navigate("/");
          }
          setEmail(""); // Reset the email field
          setPassword(""); // Reset the password field
          navigate("/admin/admin-prompt"); // Navigate to the "prompt" page
        }
      } else {
        setError("Login failed. Please check your credentials and try again.");
      }
    } catch (error) {
      setError("Login failed. Please check your credentials and try again.");
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        {error && <p className="mb-4 text-red-500">{error}</p>}

        <form onSubmit={handleSignIn}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div>

          <button
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}
