import ActivitiesTable from "./components/activities-table/ActivitiesTable";

const ActivitiesTables = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ActivitiesTable />
      </div>
    </div>
  );
};

export default ActivitiesTables;
