import React, { useState } from "react";
import { BsPen, BsTrash } from "react-icons/bs";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
// import ConfigForm from "ConfigForm/ConfigForm";
import ConfigForm from "FormModal/ConfigForm";
import { useGlobalContext } from "context/globalContext";
import toast from "react-hot-toast";
import { deleteConfigAPI } from "Api/config";

const Actions = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { setFormData, initialFormState, setFetchConfig } = useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const response = await deleteConfigAPI(
      {
        id: props.cell.row.original.id,
      },
      () => {}
    );
    if (response) {
      toast.success(`Config deleted successfully`);
      console.log("Delete successful:", response.data);
      setFetchConfig(true);
    }

    // console.log("Item deleted");
    setIsModalOpen(false);
    // getConfigkeydata();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setIsFormOpen(true);

    setFormData(
      props.cell?.row?.original
        ? {
            ...props.cell.row.original,
            status: props.cell.row.original.status ? "active" : "inactive",
          }
        : {
            ...initialFormState,
            status: initialFormState.status ? "active" : "inactive",
          }
    );
    // console.log(props, "props");
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const handleFormSubmit = (ConifData) => {
    console.log("Form submitted:", ConifData);
  };

  return (
    <div className="flex gap-4">
      <BsPen
        className="cursor-pointer fill-amber-900"
        onClick={handleFormOpen}
      />
      <BsTrash
        className="cursor-pointer fill-amber-900"
        onClick={handleDeleteClick}
      />
      <DeleteModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onDelete={handleDeleteConfirm}
      />

      <ConfigForm
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        isEditMode={true}
      />
    </div>
  );
};

export default Actions;
