import CountriesTable from "./components/countries-table/countriesTable";

const CountriesTables = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <CountriesTable />
      </div>
    </div>
  );
};

export default CountriesTables;
