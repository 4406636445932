import React, { useCallback, useEffect, useState } from "react";

import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import { format } from "date-fns";
import Progress from "components/progress";
import { useGlobalContext } from "context/globalContext";
import { getSystemPromptsAPI } from "Api/systemPromptsAPI";

const PromptTable = () => {
  const {
    getSystemPromptData,
    // setCurrentRoute,
    subCurrentRoute,
    setSubCurrentRoute,
    fetchSystemPrompts,
    setFetchSystemPrompts,
  } = useGlobalContext();
  // useEffect(() => {
  //   getSystemPromptData();
  // }, []);

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleDebounceSearchQuery = () => {
    let timeoutId;
    return function (value) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setSearchQuery(value);
      }, 300);
    };
  };

  const handleSearchQuery = handleDebounceSearchQuery();

  const getSystemPrompts = useCallback(async () => {
    const response = await getSystemPromptsAPI(
      {
        searchQuery,
      },
      setIsLoading
    );
    if (response) {
      setTableData(response?.toSorted((a, b) => a.key.localeCompare(b.key)));
      setFetchSystemPrompts(false);
    }
  }, [searchQuery, setFetchSystemPrompts]);

  useEffect(() => {
    getSystemPrompts();
  }, [getSystemPrompts]);

  useEffect(() => {
    if (fetchSystemPrompts) getSystemPrompts();
  }, [fetchSystemPrompts, getSystemPrompts]);

  const columnsData = useMemo(
    () => [
      {
        Header: "Key",
        accessor: "key",
      },
      // {
      //   Header: "Value",
      //   accessor: "value",
      // },
      {
        Header: "Version",
        accessor: "version",
      },
      {
        Header: "Status",
        accessor: "status",
      },

      {
        Header: "Comment",
        accessor: "comment",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const columns = useMemo(() => columnsData, [columnsData]);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 100000 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto "}>
      <div className="relative flex items-center justify-between ">
        <div className="text-xl font-bold text-navy-700 dark:text-white ">
          {/* /* Table A */}
          <input
            type="search"
            className="w-24 rounded-md border border-gray-300 py-2 pl-2 text-sm transition-all duration-300 ease-in-out hover:w-64 hover:border-blue-500 focus:w-64 focus:border-blue-500 focus:pr-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="🔍 Search"
            // value={searchQuery}
            onChange={(e) => {
              e.preventDefault();
              handleSearchQuery(e.target.value);
            }}
          />
        </div>
        {!subCurrentRoute && (
          <CardMenu getSystemPromptData={getSystemPromptData} />
        )}
      </div>

      <div className="mt-4 h-full overflow-x-scroll xl:overflow-auto">
        {!subCurrentRoute ? (
          <>
            {isLoading && tableData.length === 0 && <span>...Loading</span>}
            {!isLoading && tableData.length === 0 && (
              <span className="block w-full text-center">
                No System propmts found.
              </span>
            )}
            {!!tableData.length && (
              <table {...getTableProps()} className="w-full">
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, index) => {
                        if (column.Header === "Value") {
                          return (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={index}
                              className="border-b border-gray-200 pr-20 pb-[10px] text-start dark:!border-navy-700 "
                            >
                              <p className="w-72 text-xs tracking-wide text-gray-600">
                                {column.render("Header")}
                              </p>
                            </th>
                          );
                        }
                        if (column.Header === "Version") {
                          return (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={index}
                              className="w-10 border-b border-gray-200 pr-10 pb-[10px] text-start dark:!border-navy-700 "
                            >
                              <p className="w-10 text-xs tracking-wide text-gray-600">
                                {column.render("Header")}
                              </p>
                            </th>
                          );
                        }
                        return (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                            className="border-b border-gray-200 pr-20 pb-[10px] text-start dark:!border-navy-700 "
                          >
                            <p className="w-20 text-xs tracking-wide text-gray-600">
                              {column.render("Header")}
                            </p>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                          let data = cell.render("Cell");

                          if (cell.column.Header === "Status") {
                            data = (
                              <div className="flex items-center">
                                <div className={`rounded-full text-xl`}>
                                  {cell.value === true ? (
                                    <MdCheckCircle className="text-green-500" />
                                  ) : cell.value === false ? (
                                    <MdCancel className="text-red-500" />
                                  ) : cell.value === "Error" ? (
                                    <MdOutlineError className="text-orange-500" />
                                  ) : null}
                                </div>
                                {/* <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value ? "Active" : "In-Active"}
                          </p> */}
                              </div>
                            );
                          } else if (cell.column.Header === "Value") {
                            data = (
                              <div className="scrollbar-custom mr-5 max-h-[150px] overflow-auto overflow-y-auto overflow-x-hidden pt-[4px] pb-[8px] sm:text-[14px]">
                                {cell.value}
                              </div>
                            );
                          } else if (cell.column.Header === "Created At") {
                            data = format(
                              new Date(cell.value),
                              "yyyy-MM-dd HH:mm:ss"
                            );
                          } else if (cell.column.Header === "Actions") {
                            data = (
                              <Progress
                                width="w-[68px]"
                                value={cell.value}
                                cell={cell}
                              />
                            );
                          }

                          return (
                            <td
                              className="px-[7px] pt-[14px] pb-[8px] sm:text-[14px]"
                              {...cell.getCellProps()}
                              key={index}
                            >
                              <p
                                className={`max-w-fit ${
                                  cell.column.Header === "Key" &&
                                  "cursor-pointer"
                                }`}
                                onClick={(e) => setSubCurrentRoute(cell.value)}
                              >
                                {data}
                              </p>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </Card>
  );
};

export default PromptTable;
