import React, { useState } from "react";
import { BsPen, BsTrash } from "react-icons/bs";
import { useGlobalContext } from "context/globalContext";
import toast from "react-hot-toast";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { deleteCountryAPI } from "Api/countriesAPI";

const EditOrDeleteCountriesBtn = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    setCountriesFormData,
    initialCountriesFormState,
    setFetchCountries,
    setIsOpenCountriesEditForm,
  } = useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const response = await deleteCountryAPI(
      { id: props.cell.row.original.id },
      () => {}
    );
    if (response) {
      toast.success(`Country deleted successfully`);
      console.log("Delete successful:", response.data);
      setFetchCountries(true);
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setCountriesFormData(
      props.cell?.row?.original
        ? {
            ...props.cell.row.original,
          }
        : {
            ...initialCountriesFormState,
          }
    );
    setIsOpenCountriesEditForm(true);
    console.log(props, "props");
  };

  return (
    <div className="flex gap-4">
      <BsPen
        className="cursor-pointer fill-amber-900"
        onClick={handleFormOpen}
      />
      <BsTrash
        className="cursor-pointer fill-amber-900"
        onClick={handleDeleteClick}
      />
      <DeleteModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default EditOrDeleteCountriesBtn;
