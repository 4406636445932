import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { useGlobalContext } from "context/globalContext";
import CreateOrUpdateWebContent from "../create-or-update-web-content/createOrUpdateWebContentModal";
// import FormPopup from "FormModal/FormModal";

function CreateWebContentsBtn(props) {
  const { transparent } = props;
  const [open, setOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { isOpenWebsiteContentsEditForm, setIsOpenWebsiteContentsEditForm } =
    useGlobalContext();

  const handleFormOpen = () => {
    setIsFormOpen(true);
  };
  const handleFormClose = () => {
    setIsFormOpen(false);
  };
  const handleFormSubmit = (formData) => {
    console.log("Form submitted:", formData);
  };

  return (
    <div className="relative">
      <button
        onClick={() => {
          setOpen(!open);
          handleFormOpen();
        }}
        className={`flex items-center text-xl hover:cursor-pointer ${
          transparent
            ? "bg-none text-white hover:bg-none active:bg-none"
            : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
        } linear justify-center rounded-lg font-bold transition duration-200`}
      >
        <BsPlus className="fill-amber-900" />
      </button>
      <CreateOrUpdateWebContent
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        isEditMode={false}
        // getSystemPromptData={getSystemPromptData}
      />
      <CreateOrUpdateWebContent
        isOpen={isOpenWebsiteContentsEditForm}
        onClose={() => setIsOpenWebsiteContentsEditForm(false)}
        onSubmit={handleFormSubmit}
        isEditMode={true}
      />
    </div>
  );
}

export default CreateWebContentsBtn;
