import React, { useState, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useGlobalContext } from "context/globalContext";
import { IoMdClose } from "react-icons/io";
import { createOrUpdateActivitiesAPI } from "Api/activitiesAPI";

const CreateOrUpdateActivitiesModal = ({
  isOpen,
  onClose,
  onSubmit,
  isEditMode,
  //   initialFormData,
}) => {
  //   const { getConfigkeydata } = useGlobalContext();
  const {
    activitiesFormData,
    setActivitiesFormData,
    initialActivitiesFormState,
    setFetchActivities,
  } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //   useEffect(() => {
  //     if (isEditMode && initialFormData) {
  //       setDestinationFormData(initialFormData);
  //     } else {
  //       setDestinationFormData(initialDestinationFormState);
  //     }
  //   }, [
  //   ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setActivitiesFormData({
      ...activitiesFormData,
      [name]: value,
    });
    if (name === "key") {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const response = await createOrUpdateActivitiesAPI(
      activitiesFormData,
      setIsSubmitting
    );
    if (response) {
      console.log(response);
      const successMessage = activitiesFormData?.id
        ? "Activities updated successfully"
        : "Activities created successfully";
      toast.success(successMessage);

      onSubmit(response.data);
      onClose();
      setActivitiesFormData(initialActivitiesFormState);
      setFetchActivities(true);
    }
    if (!response) {
      toast.error("Error creating/updating Activities. Please try again.");
    }
  };

  const handleCancel = () => {
    setActivitiesFormData(initialActivitiesFormState);
    onClose();
  };

  const isFormValid = useMemo(() => {
    return (
      activitiesFormData?.Name &&
      activitiesFormData?.Type &&
      activitiesFormData?.Title &&
      activitiesFormData?.Subtitle &&
      activitiesFormData?.Region &&
      !errorMessage
    );
  }, [activitiesFormData, errorMessage]);

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex h-screen items-center justify-center bg-opacity-50">
      <div className="relative z-50 mx-auto flex  w-4/5 flex-col items-center justify-start rounded-xl bg-white p-6 shadow-xl">
        <IoMdClose
          onClick={handleCancel}
          className="absolute top-4 right-4 cursor-pointer text-gray-600 hover:text-gray-800"
          size={24}
        />

        <form onSubmit={handleSubmit} className="w-full">
          <div className="mt-4 flex justify-center gap-4">
            <div className="w-1/2  ">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Activity:
              </label>
              <input
                type="text"
                name="Activity"
                value={activitiesFormData?.Activity}
                // disabled={isEditMode}
                onChange={handleChange}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              {errorMessage && (
                <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
              )}
            </div>
            <div className="w-1/2  ">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Group:
              </label>
              <input
                type="text"
                name="Group"
                value={activitiesFormData?.Group}
                // disabled={isEditMode}
                onChange={handleChange}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              {errorMessage && (
                <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
              )}
            </div>
          </div>
          <div className="mt-4 mb-4 flex justify-start gap-4">
            {/* <div className="w-1/2">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Type:
              </label>
              <select
                name="Type"
                onChange={handleChange}
                value={destinationFormData.Type}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {configConstants.map((config, idx) => (
                  <option key={idx} value={config.value}>
                    {config.type}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="w-1/2">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Subtitle:
              </label>
              <textarea
                name="Subtitle"
                value={activitiesFormData?.Subtitle}
                onChange={handleChange}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              ></textarea>
            </div>
          </div>
          <div className="mb-8 flex justify-end space-x-4">
            <button
              type="submit"
              className={`rounded-md bg-green-600 py-2 px-4 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                isSubmitting ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={isSubmitting}
            >
              {isEditMode ? "Update" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrUpdateActivitiesModal;
