import DestinationTable from "./components/destination-table/destinationTable";

const DestinationTables = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <DestinationTable />
      </div>
    </div>
  );
};

export default DestinationTables;
