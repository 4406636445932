import React, { useState } from "react";
import { BsPen, BsTrash } from "react-icons/bs";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import FormPopup from "FormModal/FormModal";
import { useGlobalContext } from "context/globalContext";
import toast from "react-hot-toast";
import { deleteSystemPromptsAPI } from "Api/systemPromptsAPI";

const Progress = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { setFormData, initialFormState, setFetchSystemPrompts } =
    useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  // const handleDeleteConfirm = async () => {
  //   const response = await deleteSystemPromptsAPI(
  //     {
  //       id: props.cell.row.original.id,
  //     },
  //     () => {}
  //   );
  //   if (response) {
  //     toast.success(`Config deleted successfully`);
  //     console.log("Delete successful:", response.data);
  //   }

  //   // console.log("Item deleted");
  //   setIsModalOpen(false);
  //   getConfigkeydata();
  // };

  const handleDeleteConfirm = async () => {
    const response = await deleteSystemPromptsAPI(
      {
        id: props.cell.row.original.id,
      },
      () => {}
    );
    if (response) {
      toast.success(`System prompt deleted successfully`);
      console.log("Delete successful:", response.data);
      setFetchSystemPrompts(true);
    }

    // console.log("Item deleted");
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setIsFormOpen(true);

    setFormData(
      props.cell?.row?.original
        ? {
            ...props.cell.row.original,
            status: props.cell.row.original.status ? "active" : "inactive",
          }
        : {
            ...initialFormState,
            status: initialFormState.status ? "active" : "inactive",
          }
    );
    console.log(props, "props");
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const handleFormSubmit = (formData) => {
    console.log("Form submitted:", formData);
  };

  return (
    <div className="flex gap-4">
      <BsPen
        className="cursor-pointer fill-amber-900"
        onClick={handleFormOpen}
      />
      <BsTrash
        className="cursor-pointer fill-amber-900"
        onClick={handleDeleteClick}
      />
      <DeleteModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onDelete={handleDeleteConfirm}
      />

      <FormPopup
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        isEditMode={true}
      />
    </div>
  );
};

export default Progress;
