import React, { useState } from "react";
import { BsPen, BsTrash } from "react-icons/bs";
import { useGlobalContext } from "context/globalContext";
import toast from "react-hot-toast";
import axios from "axios";
import { ENDPOINT } from "constants/environment";
import DeleteModal from "components/DeleteModal/DeleteModal";
import { deleteWebContentsAPI } from "Api/webContentAPI";

const EditAndDeleteWebContents = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    setWebContentsFormData,
    initialWebContentsFormState,
    setFetchWebContents,
    setIsOpenWebsiteContentsEditForm,
  } = useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const response = await deleteWebContentsAPI(
      { id: props.cell.row.original.id },
      () => {}
    );
    if (response) {
      toast.success(`Web Content deleted successfully`);
      console.log("Delete successful:", response.data);
      setFetchWebContents(true);
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setWebContentsFormData(
      props.cell?.row?.original
        ? {
            ...props.cell.row.original,
          }
        : {
            ...initialWebContentsFormState,
          }
    );
    setIsOpenWebsiteContentsEditForm(true);
    console.log(props, "props");
  };

  return (
    <div className="flex gap-4">
      <BsPen
        className="cursor-pointer fill-amber-900"
        onClick={handleFormOpen}
      />
      <BsTrash
        className="cursor-pointer fill-amber-900"
        onClick={handleDeleteClick}
      />
      <DeleteModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onDelete={handleDeleteConfirm}
      />
    </div>
  );
};

export default EditAndDeleteWebContents;
