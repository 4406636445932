import React, { useCallback, useEffect, useState } from "react";

import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import { format } from "date-fns";
import { useGlobalContext } from "context/globalContext";
import CreateWebContentsBtn from "../create-web-content/createWebContent";
import { getWebContentAPI } from "Api/webContentAPI";
import EditAndDeleteWebContents from "../edit-and-delete-web-contents/editAndDeleteWebContents";

const WebContentTable = () => {
  const { fetchWebContents, setFetchWebContents } = useGlobalContext();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getWebContents = useCallback(async () => {
    const response = await getWebContentAPI(setIsLoading);
    if (response) {
      setTableData(response);
      setFetchWebContents(false);
    }
  }, [setFetchWebContents]);

  useEffect(() => {
    getWebContents();
  }, [getWebContents]);

  useEffect(() => {
    if (fetchWebContents) getWebContents();
  }, [fetchWebContents, getWebContents]);

  const columnsData = useMemo(
    () => [
      {
        Header: "Section",
        accessor: "section",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      // {
      //   Header: "Created At",
      //   accessor: "createdAt",
      // },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const columns = useMemo(() => columnsData, [columnsData]);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 100000 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto "}>
      <div className="relative flex items-center justify-between ">
        <div className="text-xl font-bold text-navy-700 dark:text-white ">
          {/* Table A */}
        </div>
        <CreateWebContentsBtn />
      </div>

      <div className="mt-4 h-full overflow-x-scroll xl:overflow-auto">
        {isLoading && tableData.length === 0 && <span>...Loading</span>}
        {!isLoading && tableData.length === 0 && (
          <span className="block w-full text-center">
            No Destinations found.
          </span>
        )}
        {!!tableData.length && (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => {
                    if (column.Header === "Value") {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={index}
                          className="border-b border-gray-200 pr-20 pb-[10px] text-start dark:!border-navy-700 "
                        >
                          <p className="w-72 text-xs tracking-wide text-gray-600">
                            {column.render("Header")}
                          </p>
                        </th>
                      );
                    }
                    if (column.Header === "Version") {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={index}
                          className="w-10 border-b border-gray-200 pr-10 pb-[10px] text-start dark:!border-navy-700 "
                        >
                          <p className="w-10 text-xs tracking-wide text-gray-600">
                            {column.render("Header")}
                          </p>
                        </th>
                      );
                    }
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={index}
                        className="border-b border-gray-200 pr-20 pb-[10px] text-start dark:!border-navy-700 "
                      >
                        <p className="w-20 text-xs tracking-wide text-gray-600">
                          {column.render("Header")}
                        </p>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = cell.render("Cell");

                      if (cell.column.Header === "Status") {
                        data = (
                          <div className="flex items-center">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === true ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : cell.value === false ? (
                                <MdCancel className="text-red-500" />
                              ) : cell.value === "Error" ? (
                                <MdOutlineError className="text-orange-500" />
                              ) : null}
                            </div>
                            {/* <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value ? "Active" : "In-Active"}
                          </p> */}
                          </div>
                        );
                      } else if (cell.column.Header === "Value") {
                        data = (
                          <div className="scrollbar-custom mr-5 max-h-[150px] overflow-auto overflow-y-auto overflow-x-hidden pt-[4px] pb-[8px] sm:text-[14px]">
                            {cell.value}
                          </div>
                        );
                      } else if (cell.column.Header === "Created At") {
                        data = format(
                          new Date(cell.value),
                          "yyyy-MM-dd HH:mm:ss"
                        );
                      } else if (cell.column.Header === "Actions") {
                        data = (
                          <EditAndDeleteWebContents
                            width="w-[68px]"
                            value={cell.value}
                            cell={cell}
                          />
                        );
                      }

                      return (
                        <td
                          className="px-[7px] pt-[14px] pb-[8px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          <p
                            className={`max-w-fit ${
                              cell.column.Header === "Key" && "cursor-pointer"
                            }`}
                          >
                            {data}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Card>
  );
};

export default WebContentTable;
