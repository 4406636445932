import axios from "axios";
import { ENDPOINT } from "constants/environment";

export const getSystemPromptsAPI = async (args, isLoading) => {
  try {
    isLoading(true);
    const URL = args?.searchQuery
      ? `${ENDPOINT}/app/systemprompt/search?searchQuery=${args.searchQuery}`
      : `${ENDPOINT}/app/v1/systemprompt`;
    const response = await axios.get(URL);
    if (response.data && response.data.statusCode === 200) {
      if (isLoading) isLoading(false);
      console.log(response, "response");
      return response?.data?.data;
    }
    if (isLoading) isLoading(false);
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error fetching data:", error);
    return null;
  }
};

export const createOrUpdateSystemPromptsAPI = async (args, isLoading) => {
  const API_URL = `${ENDPOINT}/app/v1/systemprompt`;
  try {
    if (isLoading) isLoading(true);
    const response = args?.id
      ? await axios.put(API_URL, { ...args })
      : await axios.post(API_URL, { ...args });
    if (response.data && response.data.statusCode === 200) {
      if (isLoading) isLoading(false);
      return response?.data?.data;
    }
    if (isLoading) isLoading(false);
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error creating/updating systemPrompt:", error);
    return null;
  }
};

export const deleteSystemPromptsAPI = async (args, isLoading) => {
  const API_URL = `${ENDPOINT}/app/v1/systemprompt/?id=${args.id}`;
  try {
    if (isLoading) isLoading(true);
    const response = await axios.delete(API_URL);

    if (response.data) {
      if (isLoading) isLoading(false);
      return response?.data;
    }
    if (isLoading) isLoading(false);
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error deleting system prompt:", error);
    return null;
  }
};
