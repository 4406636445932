import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useGlobalContext } from "context/globalContext";
import { ENDPOINT } from "constants/environment";
import { configConstants } from "constants/configCategory";
import { IoMdClose } from "react-icons/io";
import { createOrUpdateConfigAPI } from "Api/config";

const API_URL = `${ENDPOINT}/app/v1/config`;

const ConfigForm = ({
  isOpen,
  onClose,
  onSubmit,
  isEditMode,
  initialFormData,
}) => {
  const { setFetchConfig } = useGlobalContext();
  const { formData, setFormData, initialFormState } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isEditMode && initialFormData) {
      setFormData(initialFormData);
    } else {
      setFormData(initialFormState);
    }
  }, [isEditMode, initialFormData, initialFormState, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "key") {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      ...formData,
      status: formData.status === "active" ? true : false,
    };

    const response = await createOrUpdateConfigAPI(payload, setIsSubmitting);
    if (response) {
      console.log(response);
      const successMessage = formData?.id
        ? "Config updated successfully"
        : "Config created successfully";
      toast.success(successMessage);

      onSubmit(response.data);
      onClose();
      setFormData(initialFormState);
      // getSystemPromptData();
      setFetchConfig(true);
    }
    if (!response) {
      toast.error("Error creating/updating config. Please try again.");
    }
  };

  const handleCancel = () => {
    setFormData(initialFormState);
    onClose();
  };

  const isFormValid = useMemo(() => {
    return (
      formData.key &&
      formData.value &&
      formData.status &&
      formData.configCategory &&
      !errorMessage
    );
  }, [formData, errorMessage]);

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex h-screen items-center justify-center bg-opacity-50">
      <div className="relative z-50 mx-auto flex  w-4/5 flex-col items-center justify-start rounded-xl bg-white p-6 shadow-xl">
        <IoMdClose
          onClick={handleCancel}
          className="absolute top-4 right-4 cursor-pointer text-gray-600 hover:text-gray-800"
          size={24}
        />

        <form onSubmit={handleSubmit} className="w-full">
          <div className="mt-4 flex justify-center gap-4">
            <div className="w-1/2  ">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Key:
              </label>
              <input
                type="text"
                name="key"
                value={formData.key}
                disabled={isEditMode}
                onChange={handleChange}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {errorMessage && (
                <p className="mt-2 text-sm text-red-500">{errorMessage}</p>
              )}
            </div>
            <div className="w-1/2">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Value:
              </label>
              <textarea
                name="value"
                value={formData.value}
                onChange={handleChange}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
          </div>
          <div className="mb-4 flex justify-center gap-4">
            <div className="w-1/2">
              <label className="mb-2 block font-bold text-gray-700">
                <span className="text-red-600">*</span> Config category:
              </label>
              <select
                name="configCategory"
                onChange={handleChange}
                value={formData.configCategory}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {configConstants.map((config, idx) => (
                  <option key={idx} value={config.value}>
                    {config.type}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-1/2">
              <label className="mb-2 block font-bold text-gray-700">
                Comment:
              </label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
          </div>
          <div className="mb-4 text-left">
            <label className="mb-2 block font-bold text-gray-700">
              <span className="text-red-600">*</span> Status:
            </label>
            <div className="justify-right flex items-center">
              <label className="mr-4">
                <input
                  type="radio"
                  name="status"
                  value="active"
                  checked={formData.status === "active"}
                  onChange={handleChange}
                  className="relative top-0.5 mr-1 cursor-pointer"
                />
                Active
              </label>
              <label>
                <input
                  type="radio"
                  name="status"
                  value="inactive"
                  checked={formData.status === "inactive"}
                  onChange={handleChange}
                  className="relative top-0.5 mr-1 cursor-pointer"
                />
                In-Active
              </label>
            </div>
          </div>
          <div className="mb-8 flex justify-end space-x-4">
            <button
              type="submit"
              className={`rounded-md bg-green-600 py-2 px-4 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                isSubmitting ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={!isFormValid || isSubmitting}
            >
              {isEditMode ? "Update" : "Add"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigForm;
