import WebPromptTable from "./components/web-prompts-table/webPromptsTable";

const WebPromptsTables = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <WebPromptTable />
      </div>
    </div>
  );
};

export default WebPromptsTables;
